<template>
    <div>
        <h1>ERROR</h1>
    </div>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `error`,
    methods: {
        async logout() {
            localStorage.removeItem(`token`);
            await Network.removeToken();
            await this.$router.push(`/`);
        }
    }
}
</script>
